import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { CreateRestaurantOrderRequest, RestaurantOrder } from 'models/digitalMap';
import { createAction } from 'ducks/actionHelpers';

// Actions
const CREATE_RESTAURANT_ORDER_REQUEST = 'CREATE_RESTAURANT_ORDER_REQUEST';
const CREATE_RESTAURANT_ORDER_SUCCESS = 'CREATE_RESTAURANT_ORDER_SUCCESS';
const CREATE_RESTAURANT_ORDER_FAILURE = 'CREATE_RESTAURANT_ORDER_FAILURE';
const FETCH_RESTAURANT_ORDER_REQUEST = 'FETCH_RESTAURANT_ORDER_REQUEST';
const FETCH_RESTAURANT_ORDER_SUCCESS = 'FETCH_RESTAURANT_ORDER_SUCCESS';
const FETCH_RESTAURANT_ORDER_FAILURE = 'FETCH_RESTAURANT_ORDER_FAILURE';
const FETCH_ALL_RESTAURANT_ORDERS_REQUEST = 'FETCH_ALL_RESTAURANT_ORDERS_REQUEST';
const FETCH_ALL_RESTAURANT_ORDERS_SUCCESS = 'FETCH_ALL_RESTAURANT_ORDERS_SUCCESS';
const FETCH_ALL_RESTAURANT_ORDERS_FAILURE = 'FETCH_ALL_RESTAURANT_ORDERS_FAILURE';

// Action creators
const createRestaurantOrderRequest = () => createAction(CREATE_RESTAURANT_ORDER_REQUEST);
const createRestaurantOrderSuccess = (response: RestaurantOrder) =>
  createAction(CREATE_RESTAURANT_ORDER_SUCCESS, response);
const createRestaurantOrderFailure = (err: string) =>
  createAction(CREATE_RESTAURANT_ORDER_FAILURE, err);

export const createRestaurantOrder = (apiKey: string, req: CreateRestaurantOrderRequest) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(createRestaurantOrderRequest());
  return axios
    .post(`${config.apiUrl}/restaurantorders`, req, {
      headers: { 'x-api-key': apiKey },
    })
    .then((response) => {
      dispatch(createRestaurantOrderSuccess(response.data));
    })
    .catch((err) => {
      dispatch(createRestaurantOrderFailure(err.message));
    });
};

const fetchRestaurantOrderRequest = () => createAction(FETCH_RESTAURANT_ORDER_REQUEST);
const fetchRestaurantOrderSuccess = (response: RestaurantOrder) =>
  createAction(FETCH_RESTAURANT_ORDER_SUCCESS, response);
const fetchRestaurantOrderFailure = (err: string) =>
  createAction(FETCH_RESTAURANT_ORDER_FAILURE, err);

export const fetchRestaurantOrder = (apiKey: string, id: string) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchRestaurantOrderRequest());
  return axios
    .get(`${config.apiUrl}/restaurantorders/${id}`, {
      headers: { 'x-api-key': apiKey },
    })
    .then((response) => {
      dispatch(fetchRestaurantOrderSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchRestaurantOrderFailure(err.message));
    });
};

const fetchAllRestaurantOrdersByAccessTokenRequest = () =>
  createAction(FETCH_ALL_RESTAURANT_ORDERS_REQUEST);
const fetchAllRestaurantOrdersByAccessTokenSuccess = (response: RestaurantOrder[]) =>
  createAction(FETCH_ALL_RESTAURANT_ORDERS_SUCCESS, response);
const fetchAllRestaurantOrdersByAccessTokenFailure = (err: string) =>
  createAction(FETCH_ALL_RESTAURANT_ORDERS_FAILURE, err);

export const fetchAllRestaurantOrdersByAccessToken = (
  apiKey: string,
  contentLanguage: string,
  accessToken: string,
  applicationId: string,
  idProvider: string,
  redirectUri: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchAllRestaurantOrdersByAccessTokenRequest());
  return axios
    .get(`${config.apiUrl}/restaurantorders/lookupbyaccesstoken`, {
      params: {
        access_token: accessToken,
        application_id: applicationId,
        id_provider: idProvider,
        encoded_redirect_uri: encodeURIComponent(btoa(redirectUri)),
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(
        fetchAllRestaurantOrdersByAccessTokenSuccess(response.data?.restaurant_orders ?? [])
      );
    })
    .catch((err) => {
      dispatch(fetchAllRestaurantOrdersByAccessTokenFailure(err.message));
    });
};

export const finalizeRestaurantOrderEntry = (
  apiKey: string,
  csrfToken: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(createRestaurantOrderRequest());
  return axios
    .post(
      `${config.apiUrl}/restaurantorders/prepare/finalize`,
      {
        csrf_token: csrfToken,
      },
      {
        headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      }
    )
    .then((response) => {
      dispatch(createRestaurantOrderSuccess(response.data));
    })
    .catch((err) => {
      dispatch(createRestaurantOrderFailure(err?.response?.data?.message ?? 'error')); // TODO: define common error mesaage
    });
};

type Action =
  | ReturnType<typeof createRestaurantOrderRequest>
  | ReturnType<typeof createRestaurantOrderSuccess>
  | ReturnType<typeof createRestaurantOrderFailure>
  | ReturnType<typeof fetchRestaurantOrderRequest>
  | ReturnType<typeof fetchRestaurantOrderSuccess>
  | ReturnType<typeof fetchRestaurantOrderFailure>
  | ReturnType<typeof fetchAllRestaurantOrdersByAccessTokenRequest>
  | ReturnType<typeof fetchAllRestaurantOrdersByAccessTokenSuccess>
  | ReturnType<typeof fetchAllRestaurantOrdersByAccessTokenFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case CREATE_RESTAURANT_ORDER_FAILURE:
      return action.payload;
    case CREATE_RESTAURANT_ORDER_REQUEST:
    case CREATE_RESTAURANT_ORDER_SUCCESS:
      return '';
    default:
      return state;
  }
};

const orders = (state: RestaurantOrder[] = [], action: Action) => {
  switch (action.type) {
    case CREATE_RESTAURANT_ORDER_SUCCESS:
      return [...state, action.payload];
    case CREATE_RESTAURANT_ORDER_FAILURE:
      return state;
    case FETCH_RESTAURANT_ORDER_SUCCESS:
      return [...state.filter((order) => order.id !== action.payload.id), action.payload];
    case FETCH_ALL_RESTAURANT_ORDERS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const lastCreatedOrder = (state: RestaurantOrder | null = null, action: Action) => {
  switch (action.type) {
    case CREATE_RESTAURANT_ORDER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export interface RestaurantOrdersState {
  error: ReturnType<typeof error>;
  orders: ReturnType<typeof orders>;
  lastCreatedOrder: ReturnType<typeof lastCreatedOrder>;
}

export default combineReducers({
  error,
  orders,
  lastCreatedOrder,
});
