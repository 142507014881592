import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { addInstantWinTierIndices, InstantWin, InstantWithTierRemaining } from 'models/instantWin';
import { createAction } from 'ducks/actionHelpers';

const FETCH_INSTANT_WIN_REQUEST = 'FETCH_INSTANT_WIN_REQUEST';
const FETCH_INSTANT_WIN_SUCCESS = 'FETCH_INSTANT_WIN_SUCCESS';
const FETCH_INSTANT_WIN_FAILURE = 'FETCH_INSTANT_WIN_FAILURE';
const FETCH_INSTANT_WIN_WITH_TIER_REMAINING_REQUEST =
  'FETCH_INSTANT_WIN_WITH_TIER_REMAINING_REQUEST';
const FETCH_INSTANT_WIN_WITH_TIER_REMAINING_SUCCESS =
  'FETCH_INSTANT_WIN_WITH_TIER_REMAINING_SUCCESS';
const FETCH_INSTANT_WIN_WITH_TIER_REMAINING_FAILURE =
  'FETCH_INSTANT_WIN_WITH_TIER_REMAINING_FAILURE';

const fetchInstantWinRequest = () => createAction(FETCH_INSTANT_WIN_REQUEST);
const fetchInstantWinSuccess = (response: InstantWin) =>
  createAction(FETCH_INSTANT_WIN_SUCCESS, response);
const fetchInstantWinFailure = (err: string) => createAction(FETCH_INSTANT_WIN_FAILURE, err);

export const fetchInstantWin = (
  apiKey: string,
  instantWinId: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchInstantWinRequest());

  const path = `${config.apiUrl}/instantwins/${instantWinId}`;

  return axios
    .get(path, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;
      dispatch(fetchInstantWinSuccess(payload));
    })
    .catch((err) => {
      dispatch(fetchInstantWinFailure(err.message));
    });
};

const fetchInstantWinWithTierRemainingRequest = () =>
  createAction(FETCH_INSTANT_WIN_WITH_TIER_REMAINING_REQUEST);
const fetchInstantWinWithTierRemainingSuccess = (response: InstantWithTierRemaining) =>
  createAction(FETCH_INSTANT_WIN_WITH_TIER_REMAINING_SUCCESS, response);
const fetchInstantWinWithTierRemainingFailure = (err: string) =>
  createAction(FETCH_INSTANT_WIN_WITH_TIER_REMAINING_FAILURE, err);

export const fetchInstantWinWithTierRemaining = (
  apiKey: string,
  instantWinId: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchInstantWinWithTierRemainingRequest());

  const path = `${config.apiUrl}/instantwins/${instantWinId}/remaining`;

  return axios
    .get(path, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;
      dispatch(fetchInstantWinWithTierRemainingSuccess(payload));
    })
    .catch((err) => {
      dispatch(fetchInstantWinWithTierRemainingFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchInstantWinRequest>
  | ReturnType<typeof fetchInstantWinSuccess>
  | ReturnType<typeof fetchInstantWinFailure>
  | ReturnType<typeof fetchInstantWinWithTierRemainingRequest>
  | ReturnType<typeof fetchInstantWinWithTierRemainingSuccess>
  | ReturnType<typeof fetchInstantWinWithTierRemainingFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_INSTANT_WIN_FAILURE:
    case FETCH_INSTANT_WIN_WITH_TIER_REMAINING_FAILURE:
      return action.payload;
    case FETCH_INSTANT_WIN_REQUEST:
    case FETCH_INSTANT_WIN_SUCCESS:
    case FETCH_INSTANT_WIN_WITH_TIER_REMAINING_REQUEST:
    case FETCH_INSTANT_WIN_WITH_TIER_REMAINING_SUCCESS:
      return '';
    default:
      return state;
  }
};

const instantWin = (state: InstantWin | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_INSTANT_WIN_SUCCESS:
      return addInstantWinTierIndices(action.payload);
    case FETCH_INSTANT_WIN_FAILURE:
      return null;
    default:
      return state;
  }
};

const instantWinWithTierRemaining = (
  state: InstantWithTierRemaining | null = null,
  action: Action
) => {
  switch (action.type) {
    case FETCH_INSTANT_WIN_WITH_TIER_REMAINING_SUCCESS:
      return {
        instant_win: addInstantWinTierIndices(action.payload?.instant_win),
        tier_remaining: action.payload?.tier_remaining ?? {},
      };
    case FETCH_INSTANT_WIN_WITH_TIER_REMAINING_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface InstantWinState {
  error: ReturnType<typeof error>;
  instantWin: ReturnType<typeof instantWin>;
  instantWinWithTierRemaining: ReturnType<typeof instantWinWithTierRemaining>;
}

export default combineReducers({
  error,
  instantWin,
  instantWinWithTierRemaining,
});
