import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { createAction } from 'ducks/actionHelpers';
import {
  Customer,
  CreateCustomerRequest,
  UpdateCustomerRequest,
  IdProvider,
  CustomerToken,
} from 'models/customer';

// Actions
const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';
const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';
const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';
const FETCH_CUSTOMER_TOKEN_REQUEST = 'FETCH_CUSTOMER_TOKEN_REQUEST';
const FETCH_CUSTOMER_TOKEN_SUCCESS = 'FETCH_CUSTOMER_TOKEN_SUCCESS';
const FETCH_CUSTOMER_TOKEN_FAILURE = 'FETCH_CUSTOMER_TOKEN_FAILURE';
const CLEAR_CUSTOMER_SUCCESS = 'CLEAR_CUSTOMER_SUCCESS';
const CLEAR_CUSTOMER_ERROR_SUCCESS = 'CLEAR_CUSTOMER_ERROR_SUCCESS';
const SET_CONTINUE_WITHOUT_SIGN_IN_SUCCESS = 'SET_CONTINUE_WITHOUT_SIGN_IN_SUCCESS';
const SEND_CUSTOMER_EMAIL_REQUEST = 'SEND_CUSTOMER_EMAIL_REQUEST';
const SEND_CUSTOMER_EMAIL_SUCCESS = 'SEND_CUSTOMER_EMAIL_SUCCESS';
const SEND_CUSTOMER_EMAIL_FAILURE = 'SEND_CUSTOMER_EMAIL_FAILURE';

export const customerIdKey = 'customerId';

// Action creators
const createCustomerRequest = (payload: CreateCustomerRequest) =>
  createAction(CREATE_CUSTOMER_REQUEST, payload);
export const createCustomerSuccess = (payload: Customer) =>
  createAction(CREATE_CUSTOMER_SUCCESS, payload);
const createCustomerFailure = (payload: string) => createAction(CREATE_CUSTOMER_FAILURE, payload);

export const createCustomer = (
  apiKey: string,
  req: CreateCustomerRequest
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<any> => {
  dispatch(createCustomerRequest(req));
  return axios
    .post(`${config.apiUrl}/customers`, req, {
      headers: { 'x-api-key': apiKey },
    })
    .then((response) => {
      dispatch(createCustomerSuccess(response.data));
      localStorage.setItem(customerIdKey, response.data?.id ?? '');
    })
    .catch((err) => {
      dispatch(createCustomerFailure(err.message));
    });
};

const fetchCustomerRequest = () => createAction(FETCH_CUSTOMER_REQUEST);
const fetchCustomerSuccess = (response: Customer) => createAction(FETCH_CUSTOMER_SUCCESS, response);
const fetchCustomerFailure = (err: string) => createAction(FETCH_CUSTOMER_FAILURE, err);

export const fetchCustomer = (
  apiKey: string,
  idProvider: IdProvider,
  accessToken: string,
  redirectUri: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchCustomerRequest());
  return axios
    .get(
      `${
        config.apiUrl
      }/customers/-/?id_provider=${idProvider}&access_token=${accessToken}&encoded_redirect_uri=${encodeURIComponent(
        // btoa is requred to aboid CORS issues when access from API GW from local dev env
        btoa(redirectUri)
      )}`,
      {
        headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      }
    )
    .then((response) => {
      dispatch(fetchCustomerSuccess({ ...response.data }));
      localStorage.setItem(customerIdKey, response.data?.id ?? '');
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(fetchCustomerFailure('canceled'));
      } else {
        dispatch(fetchCustomerFailure(err?.response?.data?.message || err.message));
      }
    });
};

const updateCustomerRequest = () => createAction(UPDATE_CUSTOMER_REQUEST);
const updateCustomerSuccess = (payload: Customer) => createAction(UPDATE_CUSTOMER_SUCCESS, payload);
const updateCustomerFailure = (payload: string) => createAction(UPDATE_CUSTOMER_FAILURE, payload);

export const updateCustomer = (
  apiKey: string,
  req: UpdateCustomerRequest,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(updateCustomerRequest());
  return axios
    .patch(`${config.apiUrl}/customers/-`, req, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(updateCustomerSuccess(response.data));
    })
    .catch((err) => {
      dispatch(updateCustomerFailure(err.message));
    });
};

const deleteCustomerRequest = () => createAction(DELETE_CUSTOMER_REQUEST);
const deleteCustomerSuccess = (response: Customer) =>
  createAction(DELETE_CUSTOMER_SUCCESS, response);
const deleteCustomerFailure = (err: string) => createAction(DELETE_CUSTOMER_FAILURE, err);

export const deleteCustomer = (
  apiKey: string,
  idProvider: IdProvider,
  accessToken: string,
  redirectUri: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(deleteCustomerRequest());
  return axios
    .delete(
      `${
        config.apiUrl
      }/customers/-/?id_provider=${idProvider}&access_token=${accessToken}&encoded_redirect_uri=${encodeURIComponent(
        // btoa is requred to aboid CORS issues when access from API GW from local dev env
        btoa(redirectUri)
      )}`,
      {
        headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      }
    )
    .then((response) => {
      dispatch(deleteCustomerSuccess({ ...response.data }));
      localStorage.removeItem(customerIdKey);
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(deleteCustomerFailure('canceled'));
      } else {
        dispatch(deleteCustomerFailure(err.message));
      }
    });
};

const fetchCustomerTokenRequest = () => createAction(FETCH_CUSTOMER_TOKEN_REQUEST);
const fetchCustomerTokenSuccess = (response: CustomerToken) =>
  createAction(FETCH_CUSTOMER_TOKEN_SUCCESS, response);
const fetchCustomerTokenFailure = (err: string) => createAction(FETCH_CUSTOMER_TOKEN_FAILURE, err);

export const fetchCustomerToken = (
  apiKey: string,
  idProvider: IdProvider,
  code: string,
  redirectUri: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchCustomerTokenRequest());
  return axios
    .get(
      `${
        config.apiUrl
      }/customers/-/token?id_provider=${idProvider}&code=${code}&encoded_redirect_uri=${encodeURIComponent(
        // btoa is requred to aboid CORS issues when access from API GW from local dev env
        btoa(redirectUri)
      )}`,
      {
        headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      }
    )
    .then((response) => {
      dispatch(fetchCustomerTokenSuccess({ ...response.data, api_key: apiKey }));
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(fetchCustomerTokenFailure('canceled'));
      } else {
        dispatch(fetchCustomerTokenFailure(err.message));
      }
    });
};

const sendCustomerEmailRequest = () => createAction(SEND_CUSTOMER_EMAIL_REQUEST);
const sendCustomerEmailSuccess = (response: Customer) =>
  createAction(SEND_CUSTOMER_EMAIL_SUCCESS, response);
const sendCustomerEmailFailure = (err: string) => createAction(SEND_CUSTOMER_EMAIL_FAILURE, err);

export const sendCustomerEmail = (
  apiKey: string,
  idProvider: IdProvider,
  accessToken: string,
  redirectUri: string,
  emailType: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(sendCustomerEmailRequest());
  return axios
    .get(
      `${
        config.apiUrl
      }/customers/-/send-email?id_provider=${idProvider}&access_token=${accessToken}&encoded_redirect_uri=${encodeURIComponent(
        // btoa is requred to aboid CORS issues when access from API GW from local dev env
        btoa(redirectUri)
      )}&email_type=${emailType}`,
      {
        headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      }
    )
    .then((response) => {
      dispatch(sendCustomerEmailSuccess({ ...response.data }));
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(sendCustomerEmailFailure('canceled'));
      } else {
        dispatch(sendCustomerEmailFailure(err?.response?.data?.message || err.message));
      }
    });
};

const clearCustomerSuccess = () => createAction(CLEAR_CUSTOMER_SUCCESS);
const clearCustomerErrorSuccess = () => createAction(CLEAR_CUSTOMER_ERROR_SUCCESS);

export const clearCustomer = (): ThunkAction<
  Promise<any>,
  Promise<void>,
  Record<string, unknown>,
  AnyAction
> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(clearCustomerSuccess());
  localStorage.removeItem(customerIdKey);
};

export const clearCustomerError = (): ThunkAction<
  Promise<any>,
  Promise<void>,
  Record<string, unknown>,
  AnyAction
> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(clearCustomerErrorSuccess());
};

const setCustomerContinueWithoutSignInSuccess = (continueWithoutSignIn: boolean) =>
  createAction(SET_CONTINUE_WITHOUT_SIGN_IN_SUCCESS, continueWithoutSignIn);

export const setCustomerContinueWithoutSignIn = (
  continueWithoutSignIn: boolean
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(setCustomerContinueWithoutSignInSuccess(continueWithoutSignIn));
};

type Action =
  | ReturnType<typeof createCustomerRequest>
  | ReturnType<typeof createCustomerSuccess>
  | ReturnType<typeof createCustomerFailure>
  | ReturnType<typeof fetchCustomerRequest>
  | ReturnType<typeof fetchCustomerSuccess>
  | ReturnType<typeof fetchCustomerFailure>
  | ReturnType<typeof fetchCustomerTokenRequest>
  | ReturnType<typeof fetchCustomerTokenSuccess>
  | ReturnType<typeof fetchCustomerTokenFailure>
  | ReturnType<typeof updateCustomerRequest>
  | ReturnType<typeof updateCustomerSuccess>
  | ReturnType<typeof updateCustomerFailure>
  | ReturnType<typeof deleteCustomerRequest>
  | ReturnType<typeof deleteCustomerSuccess>
  | ReturnType<typeof deleteCustomerFailure>
  | ReturnType<typeof clearCustomerSuccess>
  | ReturnType<typeof clearCustomerErrorSuccess>
  | ReturnType<typeof setCustomerContinueWithoutSignInSuccess>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case CREATE_CUSTOMER_FAILURE:
    case FETCH_CUSTOMER_FAILURE:
    case UPDATE_CUSTOMER_FAILURE:
    case DELETE_CUSTOMER_FAILURE:
      return action.payload;
    case CREATE_CUSTOMER_REQUEST:
    case CREATE_CUSTOMER_SUCCESS:
    case FETCH_CUSTOMER_REQUEST:
    case FETCH_CUSTOMER_SUCCESS:
    case UPDATE_CUSTOMER_REQUEST:
    case UPDATE_CUSTOMER_SUCCESS:
    case DELETE_CUSTOMER_REQUEST:
    case DELETE_CUSTOMER_SUCCESS:
    case CLEAR_CUSTOMER_ERROR_SUCCESS:
      return '';
    default:
      return state;
  }
};

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case CREATE_CUSTOMER_REQUEST:
    case FETCH_CUSTOMER_REQUEST:
    case UPDATE_CUSTOMER_REQUEST:
    case DELETE_CUSTOMER_REQUEST:
      return true;
    case CREATE_CUSTOMER_SUCCESS:
    case CREATE_CUSTOMER_FAILURE:
    case FETCH_CUSTOMER_SUCCESS:
    case FETCH_CUSTOMER_FAILURE:
    case UPDATE_CUSTOMER_SUCCESS:
    case UPDATE_CUSTOMER_FAILURE:
    case DELETE_CUSTOMER_SUCCESS:
    case DELETE_CUSTOMER_FAILURE:
      return false;
    default:
      return state;
  }
};

const customer = (state: Customer | null = null, action: Action) => {
  switch (action.type) {
    case CREATE_CUSTOMER_SUCCESS:
    case FETCH_CUSTOMER_SUCCESS:
    case UPDATE_CUSTOMER_SUCCESS:
      return action.payload;
    case CLEAR_CUSTOMER_SUCCESS:
    case DELETE_CUSTOMER_SUCCESS:
      return null;
    default:
      return state;
  }
};

const tokenLoading = (state = false, action: Action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_TOKEN_REQUEST:
      return true;
    case FETCH_CUSTOMER_TOKEN_SUCCESS:
    case FETCH_CUSTOMER_TOKEN_FAILURE:
      return false;
    default:
      return state;
  }
};

const tokenError = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_TOKEN_FAILURE:
      return action.payload;
    case FETCH_CUSTOMER_TOKEN_REQUEST:
    case FETCH_CUSTOMER_TOKEN_SUCCESS:
      return '';
    default:
      return state;
  }
};

const customerToken = (state: CustomerToken | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_TOKEN_SUCCESS:
      return action.payload;
    case FETCH_CUSTOMER_TOKEN_REQUEST:
    case CLEAR_CUSTOMER_SUCCESS:
      return null;
    default:
      return state;
  }
};

const continueWithoutSignIn = (state = false, action: Action) => {
  switch (action.type) {
    case SET_CONTINUE_WITHOUT_SIGN_IN_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export interface CustomerState {
  error: ReturnType<typeof error>;
  loading: ReturnType<typeof loading>;
  customer: ReturnType<typeof customer>;
  tokenLoading: ReturnType<typeof tokenLoading>;
  tokenError: ReturnType<typeof tokenError>;
  customerToken: ReturnType<typeof customerToken>;
  continueWithoutSignIn: ReturnType<typeof continueWithoutSignIn>;
}

export default combineReducers({
  error,
  loading,
  customer,
  tokenLoading,
  tokenError,
  customerToken,
  continueWithoutSignIn,
});
