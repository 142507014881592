import * as React from 'react';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { getLogoUrl } from 'lib/util/imageUrl';
import { encodeCategoryNameForURI } from 'lib/util/encodeCategoryNameForURI';
import {
  selectCategories,
  selectFeatures,
  selectTopPageSections,
  selectExternalLinks,
} from 'lib/util/privateMarketplace';
import { ReduxState } from 'ducks';
import { selectBookmarkedProductIds } from 'ducks/client/bookmarks';
import { selectVisitedProductIds } from 'ducks/client/visitedProducts';
import { appendQueryString } from 'lib/util/appendQueryString';
import { useCustomerLedgerEnabled } from 'hooks/useCustomerLedgerEnabled';

import styles from './Header.module.css';
import { useQueryString } from 'hooks/useQueryString';

import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { getThemeCssOverride } from 'components/Themes';

import { LoginItem } from './LoginItem';

export const Header = () => {
  const { t } = useTranslation();

  const [visitedProductIds, setVisitedProductIds] = React.useState<string[]>([]);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!mobileMenuIsOpen) {
      document.body.classList.remove(styles['c-header__fixed']);
    } else {
      document.body.classList.add(styles['c-header__fixed']);
    }

    return () => document.body.classList.remove(styles['c-header__fixed']);
  }, [mobileMenuIsOpen]);

  const settings = useSelector((state: ReduxState) => state.server.settings.all);

  const showCovid19 = settings?.essential_pages?.covid19_settings?.visibility;

  const showFaq =
    settings?.essential_pages?.faq_settings?.faqs?.length > 0 &&
    settings?.essential_pages?.faq_settings?.visibility;

  const showAboutUs = settings?.essential_pages?.about_us_settings?.visibility;
  const myPageFeatureEnabled = useSelector(
    (state: ReduxState) =>
      state.server.settings.all?.subscriptions?.some(
        (subscription) =>
          subscription.key === 'feature-guest-my-page' && subscription.status === 'ACTIVE'
      ) ?? false
  );

  const customerLedgerEnabled = useCustomerLedgerEnabled();

  const showReservationsPage =
    myPageFeatureEnabled &&
    !!settings?.guest_my_page_settings?.allow_viewing_reservation_details &&
    !customerLedgerEnabled;

  const logoUrl = useSelector((state: ReduxState) => state.server.settings.all.logo_url);
  const categories = useSelector(selectCategories);
  const features = useSelector(selectFeatures);
  const visibleSections = useSelector(selectTopPageSections);
  const bookmarks = useSelector(selectBookmarkedProductIds);
  const visitedProductIdsFromState = useSelector(selectVisitedProductIds);
  const externalLinks = useSelector(selectExternalLinks);
  const customPages = useSelector((state: ReduxState) => state.server.customPages.all);

  // Set visited product ids in useEffect to silence React hydration error
  // https://nextjs.org/docs/messages/react-hydration-error
  React.useEffect(() => {
    setVisitedProductIds(visitedProductIdsFromState);
  }, [visitedProductIdsFromState]);

  const sortedCustomPages = (customPages || []).filter((customPage) => {
    return customPage?.type === 'NORMAL' && !customPage?.enable_no_index;
  });

  const showNews = (customPages || []).find((customPage) => {
    return customPage?.type === 'NEWS' && !customPage?.enable_no_index;
  });

  const queryString = useQueryString();

  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  const showInfoItems =
    showCovid19 ||
    showFaq ||
    showAboutUs ||
    showNews ||
    sortedCustomPages.length > 0 ||
    externalLinks.length > 0;

  return (
    <header
      className={clsx(styles['c-header'], getThemeCssOverride(bookingWidgetTheme, 'c-header'))}
    >
      <div className={styles['c-header__inner']}>
        <Link href={appendQueryString('/top', queryString)} className={styles['c-header__ttl']}>
          <div className={styles['c-header__ttl__logo']}>
            <img src={getLogoUrl(logoUrl)} alt="logo" />
          </div>
        </Link>

        <input
          className={styles['c-header__flg']}
          type="checkbox"
          id="menu"
          checked={mobileMenuIsOpen}
          onChange={() => {
            setMobileMenuIsOpen(!mobileMenuIsOpen);
          }}
        />
        <label className={styles['c-header__menu']} htmlFor="menu">
          <span />
        </label>

        <div className={styles['c-header__nav']}>
          <ul className={clsx(styles['c-header__nav__list'], 'maincolor-txt')}>
            {showReservationsPage && (
              <li className={styles['c-header__nav__list__item']}>
                <Link href={appendQueryString('/reservations/verify', queryString)}>
                  {t('Reservations')}
                </Link>
              </li>
            )}
            {visibleSections.includes('CATEGORY') && (
              <li className={styles['c-header__nav__list__item']}>
                <div className={styles['c-header__nav__list__item__childrenMenu']}>
                  <p>{t('Categories')}</p>
                  <ul className="maincolor-border">
                    {categories.map((category, idx) => (
                      <li key={idx}>
                        <Link
                          href={appendQueryString(
                            `/top/${encodeCategoryNameForURI(category.name)}`,
                            queryString
                          )}
                        >
                          {category.display_name || category.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            )}
            {visibleSections.includes('FEATURE') && (
              <li className={styles['c-header__nav__list__item']}>
                <div className={styles['c-header__nav__list__item__childrenMenu']}>
                  <p>{t('Featured')}</p>
                  <ul className="maincolor-border">
                    {features.map((feature, idx) => (
                      <li key={idx}>
                        <Link
                          href={appendQueryString(
                            `/top/${encodeCategoryNameForURI(feature.name)}`,
                            queryString
                          )}
                        >
                          {feature.display_name || feature.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            )}
            {visibleSections.includes('POPULAR_PRODUCTS') && (
              <li className={styles['c-header__nav__list__item']}>
                <Link href={appendQueryString('/top/popular', queryString)}>
                  {t('Popularity Ranking')}
                </Link>
              </li>
            )}
            {visibleSections.includes('RECOMMENDED_PRODUCTS') && (
              <li className={styles['c-header__nav__list__item']}>
                <Link href={appendQueryString('/top/recommended', queryString)}>
                  {t('Recommended')}
                </Link>
              </li>
            )}
            {bookmarks.length > 0 && (
              <li className={styles['c-header__nav__list__item']}>
                <Link href={appendQueryString('/top/bookmarked', queryString)}>
                  {t('Bookmarked')}
                </Link>
              </li>
            )}
            {visitedProductIds.length > 0 && (
              <li className={styles['c-header__nav__list__item']}>
                <Link href={appendQueryString('/top/visited', queryString)}>
                  {t('Recently Viewed')}
                </Link>
              </li>
            )}
            {showInfoItems && (
              <li className={styles['c-header__nav__list__item']}>
                <div className={styles['c-header__nav__list__item__childrenMenu']}>
                  <p>{t('Info')}</p>
                  <ul className="maincolor-border" style={{ left: 'calc(-220px)' }}>
                    <>
                      {showAboutUs && (
                        <li>
                          <Link href={appendQueryString('/about', queryString)}>
                            {t('About Us')}
                          </Link>
                        </li>
                      )}
                      {showFaq && (
                        <li>
                          <Link href={appendQueryString('/faq', queryString)}>{t('FAQ')}</Link>
                        </li>
                      )}
                      {showCovid19 && (
                        <li>
                          <Link href={appendQueryString('/covid19', queryString)}>
                            {t('COVID-19 Guidelines')}
                          </Link>
                        </li>
                      )}
                      {sortedCustomPages.map((page, idx) => {
                        return (
                          <li key={idx}>
                            <Link href={appendQueryString(`/article/${page?.path}`, queryString)}>
                              {page?.title}
                            </Link>
                          </li>
                        );
                      })}
                      {showNews && (
                        <li>
                          <Link href={appendQueryString('/news', queryString)}>{t('NEWS')}</Link>
                        </li>
                      )}
                    </>
                    {externalLinks.map((link, idx) => (
                      <li key={idx}>
                        <a href={link.url} target="_blank" rel="noopener noreferrer">
                          {link.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            )}
            {customerLedgerEnabled && <LoginItem />}
          </ul>
          {/*
          <label className={styles["c-header__lang base-select">
            <img
              className={styles["c-header__lang__ic"
              src="/static/images/ic_lang.svg"
              alt="Language"
            />
            <LanguageSelect className="base-select__item" />
          </label>
           */}
        </div>
      </div>
    </header>
  );
};
