export type InstantWin = {
  id?: string;
  supplier_id?: string;
  language?: 'EN_US' | 'JA_JP' | 'KO_KR' | 'ZH_CN' | 'ZH_TW';
  page_design_contents?: {
    title?: string;
    description?: string;
    thumbnail_url?: string;
  };
  status?: InstantWinStatus;
  tiers?: {
    key?: string;
    title?: string;
    description?: string;
    thumbnail_url?: string;
    type?: InstantWinTierType;
    product_id_for_e_ticket?: string;
    stock?: number;
    probability?: number;
  }[];
  draw_type?: 'INSTANT_WIN_DRAW_TYPE_ROULETTE' | 'INSTANT_WIN_DRAW_TYPE_SCRATCH';
  draw_triggers?: InstantWinDrawTrigger[];
  available_date_from?: string;
  available_date_to?: string;
  distribution_type?: InstantWinDistributionType;
};

export type InstantWinStatus = 'INSTANT_WIN_ACTIVE' | 'INSTANT_WIN_INACTIVE';

export type InstantWinTierType =
  | 'INSTANT_WIN_TIER_TYPE_E_TICKET'
  | 'INSTANT_WIN_TIER_TYPE_COUPON'
  | 'INSTANT_WIN_TIER_TYPE_DOWNLOAD'
  | 'INSTANT_WIN_TIER_TYPE_PROMO_CODE'
  | 'INSTANT_WIN_TIER_TYPE_GIFT';

export type InstantWinDrawTrigger = {
  key?: string;
  trigger_type?: InstantWinTriggerType;
  product_ids?: string[];
  survey_ids?: string[];
};

export type InstantWinDistributionType =
  | 'INSTANT_WIN_DISTRIBUTION_TYPE_QUANTITY'
  | 'INSTANT_WIN_DISTRIBUTION_TYPE_PROBABILITY';

export type InstantWinTriggerType =
  | 'INSTANT_WIN_TRIGGER_TYPE_RESERVATION_CREATED'
  | 'INSTANT_WIN_TRIGGER_TYPE_SURVEY_ANSWERED'
  | 'INSTANT_WIN_TRIGGER_TYPE_REVIEW_POSTED'
  | 'INSTANT_WIN_TRIGGER_TYPE_STAMP_RALLY_COMPLETED'
  | 'INSTANT_WIN_TRIGGER_TYPE_CUSTOMER_REGISTERED';

export type TierRemaining = {
  tier_key: string;
  remaining: number;
};

export type InstantWithTierRemaining = {
  instant_win: InstantWin;
  tier_remaining: TierRemaining[];
};
export const addInstantWinTierIndices = (instantWin: InstantWin): InstantWin => {
  return {
    ...instantWin,
    tiers: instantWin.tiers?.map((tier, idx) => ({
      ...tier,
      index: idx,
    })),
  };
};
