import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReduxState } from 'ducks';

import { FooterLink } from 'models/settings';

const toContentLanguage = (isoLang: string) => isoLang.toUpperCase().replace('-', '_');

export const useCustomFooterLinks = (): FooterLink[] => {
  const footerLinkLayouts = useSelector(
    (state: ReduxState) =>
      state.server.settings.all.booking_widget_design_params?.footer_link_layouts
  );
  const customPages = useSelector((state: ReduxState) => state.server.customPages.all);

  const { i18n } = useTranslation();

  const footerLinks =
    footerLinkLayouts?.find((layout) => layout.language === toContentLanguage(i18n.language))
      ?.footer_links ?? [];

  const regex = /\/article\/([^\/]+)/;
  const filterFooterLinks = footerLinks?.filter((link) => {
    if (link?.url) {
      return true;
    }
    const match = link?.custom_page_path?.match(regex);
    const result = match ? match[1] : null;
    return customPages?.some((c) => c?.path === result && !c?.enable_no_index);
  });

  return filterFooterLinks ?? [];
};
