import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReduxState } from 'ducks';
import { ApiKeyContext } from 'contexts/ApiKeyContext';

import { useQueryString } from './useQueryString';

export type PageLink = {
  text: string;
  url: string;
};

export const useEssentialPageLinks = (): PageLink[] => {
  const { apiKey, useApiKeyInPaths } = React.useContext(ApiKeyContext);
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const customPages = useSelector((state: ReduxState) => state.server.customPages.all);

  const { t } = useTranslation();

  const configQueryString = useQueryString();

  const getPageUrl = (pageName: string) => {
    return `${useApiKeyInPaths ? `/${apiKey}` : ''}${pageName}?${configQueryString}`;
  };

  const showCovid19 = settings?.essential_pages?.covid19_settings?.visibility;

  const showFaq =
    settings?.essential_pages?.faq_settings?.faqs?.length > 0 &&
    settings?.essential_pages?.faq_settings?.visibility;

  const showAboutUs = settings?.essential_pages?.about_us_settings?.visibility;

  const sortedCustomPages = (customPages || []).filter((customPage) => {
    return customPage?.type === 'NORMAL' && !customPage?.enable_no_index;
  });

  const showNews = (customPages || []).find((customPage) => {
    return customPage?.type === 'NEWS' && !customPage?.enable_no_index;
  });

  const links: PageLink[] = [];
  if (showAboutUs) {
    links.push({
      url: getPageUrl('/about'),
      text: t('About Us'),
    });
  }
  if (showFaq) {
    links.push({
      url: getPageUrl('/faq'),
      text: t('FAQ'),
    });
  }
  if (showCovid19) {
    links.push({
      url: getPageUrl('/covid19'),
      text: t('COVID-19 Guidelines'),
    });
  }
  if (sortedCustomPages.length > 0) {
    links.push(
      ...sortedCustomPages.map((page) => ({
        url: getPageUrl(`/article/${page?.path}`),
        text: page?.title,
      }))
    );
  }
  if (showNews) {
    links.push({
      url: getPageUrl('/news'),
      text: t('NEWS'),
    });
  }

  return links;
};
